import { matchPath, match } from "react-router-dom";
import { MainNavMenuAsButton, MainNavMenuLink, Pill } from "@compono/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ShowIf } from "~/auth-layer";

import useFetchCounts from "~/../trackyr-ui/src/features/Messages/useFetchCounts";

import { PortalOutlineIcon } from "../icons/PortalOutlineIcon";

export const HireNav = () => {
  const { counts } = useFetchCounts();
  const { unread } = counts || { unread: 0 };
  const { candidateSearch } = useFlags();

  return (
    <MainNavMenuAsButton
      icon={PortalOutlineIcon}
      label="Hire"
      data-menu-button="true"
      notify={unread ? true : false}
    >
      <MainNavMenuLink
        data-testid="side-nav-jobs-main-nav-link"
        exact
        href="/"
        label="Jobs"
        data-is-active={(match: match, location: Location) =>
          !!match ||
          !!matchPath(location.pathname, {
            path: "/organisation/:orgId/listing/:listingId",
            exact: false,
          })
        }
      />
      {candidateSearch && (
        <MainNavMenuLink
          data-testid="side-nav-talents-main-nav-link"
          href="/talent"
          label="Talent"
        />
      )}
      <ShowIf allowedTo="applicationMessages.view">
        <MainNavMenuLink
          data-testid="side-nav-messages-main-nav-link"
          href="/messages"
          label="Messages"
          decor={
            unread ? (
              <Pill tone="critical" sx={{ ml: 3 }}>
                {unread}
              </Pill>
            ) : (
              ""
            )
          }
          notify={unread ? "onNavCollapsed" : false}
        />
      </ShowIf>
      <MainNavMenuLink
        data-testid="side-nav-interviews-main-nav-link"
        href="/interviews"
        label="Interviews"
      />
    </MainNavMenuAsButton>
  );
};
